@import "../../styles/variables";

.policy-page{
    h2{
        @include fnt(48, 48, $indigo-500, 700, $montserrat);
        margin-bottom: 64rem;
        @include tablet() {
            @include fnt(28, 36, $indigo-500, 700, $montserrat);
            margin-bottom: 32rem;
        }
    }

}

.policy-text{
    @include fnt(20, 24, $gray-900, 400);
    text-align: justify;
    @include tablet() {
        @include fnt(16, 24, $gray-900, 400);
    }

}
.back-link {
    @include fnt(18, 24, $indigo-500, 400);
    padding: 18rem 30rem 18rem 18rem;
    display: flex;
    margin-bottom: 4rem;
    padding-left: 0;
    &::before {
        content: "";
        display: block;
        background: url("../../assets/icons/arrow-narrow-left.svg") no-repeat center;
        @include size(24);
        background-size: 24rem;
        margin-right: 12rem;
        flex: none;
    }
}