@import "../../styles/variables";

.footer {
    width: 100%;
    background-color: $indigo-800;
    padding: 32rem 24rem 20rem;
    &-logos {
        display: flex;
        align-items: flex-start;
        gap: 48rem;
        margin-bottom: 48rem;
    }
    &-logo {
        width: 100%;
        max-width: 138rem;
        flex: none;
        &:hover{
            cursor: pointer;
        }
    }
    &-link{
        @include fnt(14, 20, $white, 400);

    }
}
