@import "../../styles/variables";

.main-screen {
    width: 100%;
    height: 100vh;
    position: relative;
    // background: url("../../assets/img/c133449b06e00ebf8cf1195db01866e9.jpg") no-repeat center;
    // background-size: cover;
    padding: 32rem 64rem;
    box-shadow: 0px 16px 48px 0px #a9aabe80;
    margin-bottom: 96rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: 100%;
        z-index: 0;
        filter: brightness(80%);
    }
    @include tablet() {
        padding: 32rem 16rem;
    }
    &_header {
        display: flex;
        justify-content: space-between;
        z-index: 5;
        .header-buttons {
            display: flex;
            gap: 8rem;
            @include tablet() {
                flex-direction: column;
                align-items: flex-end;
                gap: 8rem;
            }
        }
        .header-phone {
            @include fnt(24, 32, white, 500);
            margin-right: 16rem;
            @include tablet() {
                margin: 0;
            }
        }
        .header-btn {
            @include fnt(14, 20, $indigo-500, 500, $golos-ui);
            background-color: white;
            border: 1px solid $indigo-200;
            padding: 9rem 9rem 9rem 15rem;
            border-radius: 8rem;
            // box-shadow: 0px 1px 2px 0px #1018280d;
            &._telegram,
            &._phone {
                display: flex;
                width: fit-content;
                height: fit-content;
                transition: all 0.3s;
                &::after {
                    content: "";
                    display: block;
                    background-size: 20rem;
                    @include size(20);
                    margin-left: 6rem;
                }
                &:hover {
                    border: 1px solid $indigo-400;
                    background-color: $indigo-50;
                }
            }
            &._telegram {
                &::after {
                    background: url("../../assets/icons/Telegram.svg") no-repeat center;
                }
            }
            &._phone {
                &::after {
                    background: url("../../assets/icons/phone.svg") no-repeat center;
                }
            }
        }
    }
    &_content {
        // position: absolute;
        // top: 50%;
        // transform: translate(0, -50%);
        z-index: 5;
        display: grid;
        .title-block {
            display: flex;
            align-items: center;
            @include tablet() {
                justify-content: center;
            }
            .buttons-block {
                display: flex;
                @include tablet() {
                    display: none;
                }
            }
            .title-btn {
                @include fnt(32, 32, white, 500);
                padding: 32rem 40rem;
                border: 2px solid $white;
                width: fit-content;
                background-color: $yellow;
                border-radius: 80rem;
                height: fit-content;
                margin-top: 40rem;
                margin-left: 32rem;
                flex: none;
                transition: all 0.3s;
                &:hover {
                    box-shadow: 0px 4px 48px 0px #feee95;
                    background-color: $yellow-400;
                }
            }
            .title-tg {
                background: url("../../assets/icons/Telegram-white.svg") no-repeat center;
                background-size: 48rem;
                @include size(128);
                border: 2px solid $white;
                border-radius: 50%;
                margin-top: 40rem;
                margin-left: 8rem;
                flex: none;
                transition: all 0.3s;
                &:hover {
                    box-shadow: 0px 4px 48px 0px #feee95;
                    background-color: $yellow-400;
                }
            }
        }
        .address {
            @include fnt(32, 40, white, 500);
            margin-bottom: 16rem;
            display: flex;
            align-items: center;
            @include tablet() {
                @include fnt(20, 24, white, 500);
            }
            &:before {
                content: "";
                background: url("../../assets/icons/marker-pin.svg") no-repeat center;
                background-size: 40rem;
                @include size(40);
                margin-right: 8rem;
                display: block;
            }
        }

        h1 {
            @include fnt(240, 240, white, 600, $montserrat);
            // letter-spacing: 12rem;
            margin: 0;
            // box-shadow: 0px 4px 12px 0px #47546726;
            @include tablet() {
                @include fnt(120, 120, white, 600, $montserrat);
                text-align: center;
            }
        }
        h2 {
            @include fnt(120, 144, white, 600, $montserrat);
            margin: 0;
            @include tablet() {
                @include fnt(44, 52, white, 600, $montserrat);
                text-align: center;
            }
        }
        .village-info {
            display: flex;
            gap: 32rem;
            margin-top: 24rem;
            @include tablet() {
                flex-direction: column;
                width: fit-content;
                align-self: center;
                justify-self: center;
            }
            p {
                @include fnt(32, 40, white, 500);
                display: block;
                &._house,
                &._squares,
                &._map {
                    display: flex;
                    &::before {
                        content: "";
                        display: block;
                        background-size: 40rem;
                        @include size(40);
                        margin-right: 8rem;
                    }
                }
                &._house {
                    &::before {
                        background: url("../../assets/icons/home.svg") no-repeat center;
                    }
                }
                &._map {
                    &::before {
                        background: url("../../assets/icons/map.svg") no-repeat center;
                    }
                }
                &._squares {
                    &::before {
                        background: url("../../assets/icons/square-meters.svg") no-repeat center;
                    }
                }
            }
        }
    }
    .down-arrow {
        // position: absolute;
        z-index: 5;
        display: block;
        background: url("../../assets/icons/arrow-down.svg") white no-repeat center;
        background-size: 32rem;
        @include size(64);
        border-radius: 8rem 8rem 0 0;
        margin-bottom: -32rem;
        align-self: center;
        // bottom: 0;
        // left: 50%;
        // transform: translate(-50%, 0);
    }
}
