@import "../../styles/variables";

.advantages {
    margin-bottom: 96rem;
    &-grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24rem;
        @include tablet() {
            grid-template-columns:100%;
        }
    }
    .advantage {
        &-card {
            background-color: $gray-100;
            border-radius: 16rem;
            padding: 16rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            aspect-ratio: 416/240;
        }
        &-title {
            @include fnt(24, 32, $indigo-600, 600);
            margin-bottom: 8rem;
            text-align: center
        }
        &-text {
            @include fnt(20, 28, $gray-900, 400);
            text-align: center
        }
    }
    @include tablet() {
        grid-template-columns:100%;
    }
}
