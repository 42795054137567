@import "./_fonts";


$golos: "Golos", sans-serif;
$golos-ui: "Golos UI", sans-serif;
$montserrat: "Montserrat", sans-serif;
$font: $golos;

$black: #2c2d2e;
$white: #fff;
$indigo-50: #eef4ff;
$indigo-100: #e0eaff;
$indigo-200: #c7d7fe;
$indigo-400: #8098f9;
$indigo-500: #6172f3;
$indigo-600: #444ce7;
$indigo-800: #2D31A6;
$red: #f04438;
$yellow: #eaaa08;
$yellow-400: #fac515;
$main: $indigo-500;
$gray-25: #fcfcfd;
$gray-100: #f2f4f7;
$gray-200: #EAECF0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #101828;
$gray-950: #0c111d;

$screen-sm: 800px;
$screen-md: 1024px;
$screen-lg: 1440px;

@mixin size($height, $width: false) {
  height: #{$height}rem;
  @if $width {
    width: #{$width}rem;
  } @else {
    width: #{$height}rem;
  }
}

@mixin fnt($size: 16, $height: 24, $color: $black, $weight: 400, $font: $font) {
  font-family: $font;
  font-style: normal;
  @if ($font == $font) {
    font-weight: $weight;
  } @else {
    font-weight: unset;
    font-variation-settings: "wght" $weight;
  }
  font-size: #{$size}rem;
  line-height: #{$height}rem;
  color: $color;
}


$mobile-width: 600;
$tablet-width: 1024;
$laptop-width: 1440;
$hd-width: 1920;

@mixin mobile {
  @media only screen and (max-width: #{$mobile-width}px) {
      @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: #{$tablet-width}px) {
      @content;
  }
}

@mixin laptop {
  @media only screen and (max-width: #{$laptop-width}px) {
      @content;
  }
}
@mixin hd {
  @media only screen and (max-width: #{$hd-width}px) {
      @content;
  }
}
@mixin hd-plus {
  @media only screen and (min-width: #{$hd-width}px) {
      @content;
  }
}