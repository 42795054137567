@import "../../styles/variables";

.village-plan {
    &_map {
        background-color: $gray-100;
        padding-top: 16rem;
        position: relative;
        margin-bottom: 80rem;
        .map-img {
            margin-top: -172rem;
            width: 100%;
            &:hover {
                cursor: default;
            }
            @include tablet() {
                margin: 0;
            }
        }
    }
    .infrastructure {
        padding: 16rem;
        background-color: $white;
        border-radius: 16rem;
        width: fit-content;
        margin-bottom: 24rem;
        .address {
            @include fnt(24, 32, $gray-900, 400);
            display: flex;
            align-items: center;
          
            &::before {
                content: "";
                display: block;
                background-size: 24rem;
                @include size(24);
                margin-right: 8rem;
                background: url("../../assets/icons/expand.svg") no-repeat center;
            }
        }
        .comment{
            @include fnt(16, 24, $gray-500, 400);
            margin-bottom: 12rem;
            margin-left: 32rem;
        }
        .places {
            display: flex;
            gap: 8rem;
            flex-wrap: wrap;
            span {
                background-color: $indigo-100;
                border-radius: 16rem;
                padding: 4rem 12rem;
                width: fit-content;
            }
        }
    }
    .statuses {
        display: flex;
        gap: 32rem;
        flex-wrap: wrap;
        @include tablet() {
            gap: 12rem;
        }
        p {
            display: flex;
            @include fnt(16, 24, $gray-900, 400);
            align-items: center;
            &._free {
                &::before {
                    background-color: #5fe9d0;
                    border: 1px solid #2ed3b7;
                }
            }
            &._bought {
                &::before {
                    background-color: #eaaa08;
                    border: 1px solid #ca8504;
                }
            }
            &._booked {
                &::before {
                    background-color: #a9a29d;
                    border: 1px solid #79716b;
                }
            }
            &::before {
                content: "";
                display: block;
                @include size(24);
                margin-right: 8rem;
                border-radius: 4rem;
                @include tablet() {
                    @include size(16);
                }
            }
        }
    }
}
