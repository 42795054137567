@import "../../styles/variables";

.location {
    margin-bottom: 96rem;
    .yandex-map {
        position: relative;
        .map-info {
            box-shadow: 0px 8px 24px 0px #a9aabe80;
            background-color: $white;
            border-radius: 16rem;
            padding: 16rem 32rem;
            width: fit-content;
            position: absolute;
            top: 32rem;
            display: flex;
            flex-direction: column;
            gap: 8rem;
            margin-right: 16rem;
            .map {
                &-title {
                    @include fnt(24, 32, $gray-900, 600);
                }
                &-phone {
                    @include fnt(20, 24, $gray-900, 600);
                }
                &-text {
                    @include fnt(20, 24, $gray-900, 400);
                }
            }
        }
        .ya-map{
            width: 100%;
            height: 360rem;
            @include tablet() {
                height: 480rem;
            }
        }
    }
}
