@import "../../styles/variables";

.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .4);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  overflow: auto;
  z-index: 1000;
  color: $black;
  overflow-x: hidden;
  overflow-y: auto;
  &__content {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 32rem 72rem;
    margin: 0 0 0 auto;
    min-width: 456rem;
    max-width: 100vw;
    min-height: 430rem;
    height: fit-content;
    position: relative;
    width: 456rem;
    // transform: translateX(100%);
    transition: transform 0.3s;
    border-radius: 32rem;
    &._small {
      width: 420rem;
    }
    &._lightbox {
      padding: 0;
      min-width: unset;
      overflow: hidden;
      border-radius: 4rem;
      img {
        max-width: 100%;
      }
    }
   
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  &__title {
    @include fnt(48, 52, $black, 700);
    margin: 0;
  }
  &__description {
    @include fnt(20, 32, $gray-500, 500);
    margin: 0;
  }
  &__x-close {
    position: absolute;
    top: 12rem;
    right: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10rem;
    border-radius: 12rem;
    background-color: $white;
    cursor: pointer;
    transition: background-color $indigo-100;
    flex: none;
    border: none;
    &::after {
     content: '';
      display: block;
      background: url("../../assets/icons/x-close.svg") no-repeat center;
      height: 40rem;
      width: 40rem;
      background-size: 20rem;
    }
    // &:hover {
    //   background-color: $gray-100;
    // }
  }
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  &.active &__content {
    // transform: translateX(0);
  }

  &__buttons-panel {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8rem;
    align-items: center;
    margin: 24rem 0;
    * {
      justify-self: end;
    }
  }
}
.modal.inventory .modal__content {
  min-width: unset;
}
.modal-form{
    &_title{
        @include fnt(24, 32, $gray-900, 400);
   margin-bottom: 24rem;
    }
    .form-container{
        flex-direction: column;
        align-items: flex-start;
        gap: 24rem;
        margin-bottom: 8rem
    }
    .form-subtitle{
        @include fnt(14, 20, $gray-500, 400);
    }
    .form-subtitle_link{
        @include fnt(14, 20, $gray-500, 400, $golos-ui);
    }
    .form__item,
    .form-container .save_button{
        max-width: 100%;
    }
    .form__item .form-label{
        display: flex;
        &::after{
            content: "*";
            display: block;
            @include fnt(14, 20, $red, 500, $golos-ui);
        }
    }
    
}