@import "../../styles/variables";

.houses-options {
    margin-bottom: 96rem;
    &_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24rem;
        grid-template-rows: auto;
        @include tablet() {
            grid-template-columns: 100%;
        }
        .houses-options_subtitle {
            @include fnt(24, 32, $gray-500, 400);
            margin-bottom: 12rem;
        }
        .houses-options_list {
            @include fnt(24, 32, $gray-900, 400);
            margin-bottom: 8rem;
            display: flex;
            align-items: flex-start;
            &:before {
                content: "";
                display: block;
                @include size(8);
                background-color: $indigo-500;
                border-radius: 50%;
                margin-right: 8rem;
                flex: none;
                margin-top: 12rem;
            }
        }
    }
    .house-card {
        background-color: $gray-100;
        border-radius: 16rem;
        // aspect-ratio: 636/652;
        width: 100%;
        display: grid;
        grid-template-rows: 90fr 73fr;
        overflow: hidden;
        @include tablet() {
            grid-template-rows: unset;
        }
        &_cover {
            width: 100%;
            max-height: 360rem;
            @include tablet() {
                height: 360rem;
            }
            .cover-img {
                width: 100%;
                object-fit: cover;
                object-position: center;
                height: 100%;
            }
        }
        &_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 24rem;
            padding: 24rem;
            background-color: $gray-100;
            height: 100%;
            @include tablet() {
               padding: 16rem;
            }
            .content-title {
                @include fnt(32, 40, $gray-500, 400);
            }
            .content-area {
                @include fnt(32, 40, $gray-900, 600);
                margin-right: 8rem;
            }
            .house-info {
                display: flex;
                flex-wrap: wrap;
                gap: 8rem;
                &_rel {
                    @include fnt(20, 24, $gray-900, 400);
                    padding: 4rem 12rem;
                    background-color: $white;
                    border-radius: 16rem;
                }
            }
        }
        &_buttons {
            display: flex;
            gap: 8rem;
        }
    }
}
