@font-face {
  font-family: "Golos";
  src:
    url("../assets/fonts/Golos/golos-text_regular.woff2") format("woff2"),
    url("../assets/fonts/Golos/golos-text_regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Golos";
  src:
    url("../assets/fonts/Golos/golos-text_demibold.woff2") format("woff2"),
    url("../assets/fonts/Golos/golos-text_demibold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Golos";
  src:
    url("../assets/fonts/Golos/golos-text_bold.woff2") format("woff2"),
    url("../assets/fonts/Golos/golos-text_bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Golos UI";
  src:
    local("Golos UI"),
    url("../assets/fonts/Golos-UI-VF/golos-ui_vf.woff2") format("woff2"),
    url("../assets/fonts/Golos-UI-VF/golos-ui_vf.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src:
    // url("../assets/fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
}