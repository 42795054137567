@import "./_variables";

html {
    font-size: 1.2px;
    scroll-behavior: smooth;
}

@media screen and (max-width: $screen-lg) {
    html {
        font-size: calc((100 * 100vw / 1440 / 100));
    }
}
@media screen and (max-width: $screen-md) {
    html {
        font-size: calc((100 * 100vw / 1024 / 100));
    }
}
@media screen and (max-width: $screen-sm) {
    html {
        font-size: calc((100 * 100vw / 400 / 100));
    }
}

body {
    display: block;
    width: 100%;
    font-size: 16rem;
    line-height: 24rem;
    margin: 0;
    background-color: $white;
}
#root {
    display: block;
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.w-100 {
    width: 100%;
}

b {
    font-weight: unset;
}
// img {
//     &:hover {
//         cursor: default;
//     }
// }

$indents: 0, 4, 6, 8, 12, 16, 22, 24, 32, 48, 54, 96, 144;
.gap {
    @each $gap in $indents {
        &-#{$gap} {
            gap: #{$gap}rem !important;
        }
    }
}
.m {
    @each $margin in $indents {
        &-#{$margin} {
            margin: #{$margin}rem !important;
        }
        &t-#{$margin} {
            margin-top: #{$margin}rem !important;
        }
        &b-#{$margin} {
            margin-bottom: #{$margin}rem !important;
        }
        &l-#{$margin} {
            margin-left: #{$margin}rem !important;
        }
        &r-#{$margin} {
            margin-right: #{$margin}rem !important;
        }
        &y-#{$margin} {
            margin-top: #{$margin}rem !important;
            margin-bottom: #{$margin}rem !important;
        }
        &x-#{$margin} {
            margin-right: #{$margin}rem !important;
            margin-left: #{$margin}rem !important;
        }
    }
}
.p {
    @each $padding in $indents {
        &-#{$padding} {
            padding: #{$padding}rem !important;
        }
        &t-#{$padding} {
            padding-top: #{$padding}rem !important;
        }
        &b-#{$padding} {
            padding-bottom: #{$padding}rem !important;
        }
        &l-#{$padding} {
            padding-left: #{$padding}rem !important;
        }
        &r-#{$padding} {
            padding-right: #{$padding}rem !important;
        }
        &y-#{$padding} {
            padding-top: #{$padding}rem !important;
            padding-bottom: #{$padding}rem !important;
        }
        &x-#{$padding} {
            padding-right: #{$padding}rem !important;
            padding-left: #{$padding}rem !important;
        }
    }
}
.hidden {
    overflow: hidden;
    height: 100%;
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    /* Other browsers */
    overscroll-behavior: none;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

.d-none {
    display: none !important;
}
.d-flex {
    display: flex !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-column-reverse {
    flex-direction: column-reverse !important;
}
.justify-space-between {
    justify-content: space-between;
}
.justify-content-center {
    justify-content: center;
}
.align-items-center {
    align-items: center;
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
        cursor: pointer!important;
    }
}
p {
    margin: 0;
}
strong {
    // font-variation-settings: "wght" 700;
    font-weight: 700;
}
h2 {
    @include fnt(80, 80, $indigo-500, 700, $montserrat);
    margin: 0;
    @include tablet() {
        @include fnt(32, 40, $indigo-500, 700, $montserrat);
    }
}


.container-center {
    max-width: 1424rem;
    padding: 0 64rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @include tablet() {
        padding: 0 16rem;
    }
}

.slider-banner{
    width: 100%;
    background-color: $gray-100;
    margin-bottom: 96rem;
}

._btn{
    @include fnt(16, 20, $white, 500);
    border-radius: 8rem;
    padding: 12rem;
    box-shadow: 0px 1px 2px 0px #1018280D;
    width: 100%;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    &._border{
        background-color: $white;
        color: $main;
        border: 1px solid #C7D7FE
    }
    &._color{
        background-color: $main;
    }
}
.disabled {
    pointer-events: none;
    opacity: 0.3;
}