@import "../../styles/variables";

.application {
    margin-bottom: 112rem;
    &-subtitle {
        @include fnt(24, 32, $gray-900, 400);
        max-width: 636rem;
        margin-bottom: 28rem;
    }
    &-block {
        gap: 24rem;
        display: grid;
        grid-template-columns: 161fr 51fr;
        @include tablet() {
            grid-template-columns: 100%;
        }
    }
}
.consultation-form {
    background-color: $gray-100;
    border-radius: 16rem;
    padding: 24rem 32rem;
}
.tg-application {
    padding: 24rem;
    background-color: $indigo-100;
    border-radius: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include fnt(20, 32, $gray-900, 400);
    span {
        @include fnt(20, 32, $indigo-500, 600);
    }
    &::after {
        content: "";
        display: block;
        background: url("../../assets/icons/Telegram.svg") center no-repeat;
        @include size(32);
        background-size: 32rem;
        flex: none;
        margin-left: 4rem;
    }
    &:hover {
        cursor: pointer;
    }
}
.form {
    &-subtitle {
        @include fnt(16, 24, $gray-900, 400);
        &_link {
            @include fnt(16, 24, $gray-900, 600);
        }
    }
    &-container {
        display: flex;
        align-items: flex-end;
        gap: 32rem;
        margin-bottom: 16rem;
        @include tablet() {
            align-items: flex-start;
            flex-direction: column;
        }
        .save_button {
            @include fnt(16, 20, $white, 500, $golos-ui);
            background-color: $indigo-500;
            border-radius: 8rem;
            padding: 12rem;
            text-align: center;
            width: 100%;
            max-width: 280rem;
            height: fit-content;
            box-shadow: 0px 1px 2px 0px #1018280d;
            transition: background-color 0.3s;
            @include tablet() {
                max-width: unset;
            }
            &:hover {
                cursor: pointer;
                background-color: $indigo-800;
            }
        }
    }
    &__item {
        display: flex;
        flex-direction: column-reverse;
        gap: 4rem;
        max-width: 280rem;
        width: 100%;
        @include tablet() {
            max-width: unset;
        }
        .form-field {
            padding: 10rem 12rem;
            border-radius: 8rem;
            background-color: $white;
            @include fnt(16, 24, $gray-900, 400, $golos-ui);
            outline: none;
            border: 1px solid $gray-300;
            box-shadow: 0px 1px 2px 0px #1018280d;
        }
        .form-label {
            @include fnt(14, 20, $gray-700, 500, $golos-ui);
            margin-left: 12rem;
        }
    }
}
.subscrips {
    @include fnt(14, 20, $gray-600, 400, $golos-ui);
    margin-top: 4rem;
    padding: 0 12rem;
}
.disabled {
    pointer-events: none;
    opacity: 0.3;
}
.form-success {
    .form-sended_title {
        @include fnt(24, 32, $indigo-500, 500);
        display: flex;
        &::after {
            content: "";
            display: block;
            background: url("../../assets/icons/check.svg") center no-repeat;
            @include size(32);
            background-size: 32rem;
            flex: none;
            margin-left: 8rem;
        }
    }
    .form-sended_subtitle {
        @include fnt(16, 24, $gray-700, 400);
    }
}

