@import "../../styles/variables";

.house {
    &-page {
        &_content {
            padding-top: 16rem;
            background-color: $gray-100;
            padding-bottom: 144rem;
        }
        .back-link {
            @include fnt(18, 24, $indigo-500, 400);
            padding: 18rem 30rem 18rem 18rem;
            display: flex;
            margin-bottom: 4rem;
            &::before {
                content: "";
                display: block;
                background: url("../../assets/icons/arrow-narrow-left.svg") no-repeat center;
                @include size(24);
                background-size: 24rem;
                margin-right: 12rem;
                flex: none;
            }
        }
        .house-slider {
            width: 100%;
            .content-slider .slider-img_img {
                aspect-ratio: 1296 / 848;
            }
        }
        .about-house {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24rem;
            margin-bottom: 64rem;
            @include tablet() {
                grid-template-columns: 100%;
            }
            .house-card {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background-color: $white;
                border-radius: 24rem;
                padding: 24rem;
                width: 100%;
            }
            .house-info {
                display: flex;
                flex-direction: column;
                gap: 24rem;
                .content-title {
                    @include fnt(32, 40, $gray-900, 400);
                }
                .content-area {
                    @include fnt(32, 40, $gray-900, 600);
                }
                .house-tags {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8rem;
                }
                &_rel {
                    @include fnt(20, 24, $gray-900, 400);
                    padding: 4rem 12rem;
                    background-color: $gray-200;
                    border-radius: 16rem;
                }
                .house-description {
                    ul {
                        padding: 0;
                        li {
                            display: flex;
                            align-items: flex-start;
                            @include fnt(24, 32, $gray-900, 400);
                            @include tablet() {
                                @include fnt(20, 32, $gray-900, 400);
                            }
                            &:before {
                                content: "";
                                display: block;
                                @include size(8);
                                background-color: $indigo-500;
                                border-radius: 50%;
                                margin-right: 8rem;
                                flex: none;
                                margin-top: 12rem;
                            }
                        }
                    }
                }
            }
            .house-plan {
                width: 100%;
                height: fit-content;
                .plan-img {
                    width: 100%;
                    object-fit: contain;
                    object-position: top;
                    height: 100%
                }
                .plan-text {
                    // position: absolute;
                    // bottom: -32rem;
                    // left: 0;
                    @include fnt(16, 24, $gray-500, 400);
                }
            }
        }
    }
}
