@import "../../styles/variables";

.buying-options {
    margin-bottom: 96rem;
    &_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24rem;
        @include tablet() {
            grid-template-columns:100%;
        }
    }
    .option-item {
        background-color: $gray-100;
        border-radius: 16rem;
        padding: 32rem;
        aspect-ratio: 1/1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .option {
            &-percent {
                @include fnt(24, 32, $gray-900, 600);
                text-align: center
            }
            &-img {
            }
            &-text {
                @include fnt(14, 20, $gray-500, 400);
            }
            &-condition {
                @include fnt(16, 24, $gray-500, 400);
                text-align: center;
            }
        }
    }
}
