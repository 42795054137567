@import "../../styles/variables";


.discount {
    margin-bottom: 96rem;
    .subtitle{
        @include fnt(16, 24, $gray-500, 400);

    }
    &_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24rem;
        @include tablet() {
            grid-template-columns:100%;
        }
    }
    .option-item {
        background-color: $gray-100;
        border-radius: 16rem;
        padding: 32rem;
        // aspect-ratio: 416/260;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .option {
            &-discount {
                @include fnt(24, 32, $gray-900, 600);
                text-align: center;
            }
            &-img {
                max-height: 80rem;
            }
            &-area {
                @include fnt(16, 24, $gray-500, 400);
                margin-bottom: 16rem;
                text-align: center;
            }
            &-text {
                @include fnt(20, 28, $gray-900, 400);
                text-align: center;
            }
        }
    }
}
