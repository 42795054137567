@import "../../styles/variables";

.content-slider {
    width: 100%;
    position: relative;
    .swiper-wrapper {
        // aspect-ratio: 636 / 356;
    }
    .slider-img {
        overflow: hidden;

        &_img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-fit: cover;
            object-position: center;
            aspect-ratio: 1296 / 728;
        }
        &_text {
            @include fnt(20, 24, $gray-900, 400);
            display: block;
            background-color: $white;
            padding: 12rem 16rem;
            border-radius: 8rem;
            position: absolute;
            top: 24rem;
            left: 24rem;
            z-index: 50;
        }
    }
    .GallerySlider__next,
    .GallerySlider__prev {
        display: block;
        padding: 16rem;
        width: fit-content;
        background: url("../../assets/icons/arrow-down.svg") white no-repeat center;
        transform: rotate(-90deg);
        background-size: 32rem;
        @include size(64);
        @include tablet() {
            @include size(48);
            background-size: 24rem;
        }
    }
    .GallerySlider__prev {
        transform: rotate(90deg);
    }
    .container-pagination {
        @include fnt(14, 20, $gray-400, 400);
        display: flex;
        align-items: center;
        margin-left: 12rem;
        width: fit-content;
    }
    .slider-tools {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 50;
    }
}
